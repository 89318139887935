<template>
	<!-- begin: card-list -->
	<ul class="card-list">
		<li class="card-item" v-for="(item, idx) in items" :key="idx">
			<router-link to="detail" class="card-item__link">
				<div class="card-item__image">
					<img :src="item.rprsImgPtUrl" :alt="item.issueTit" />
				</div>
				<div class="card-item__title" v-if="showTitle">
					{{ item.issueTit }}
				</div>
			</router-link>
		</li>
	</ul>
	<!-- end: card-list -->
</template>

<script>
export default {
	name: 'CardList',
	props: {
		items: Array,
		viewLink: String,
		showTitle: Boolean,
	},
};
</script>
